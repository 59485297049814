import axios from 'axios';
import { FETCH_REQUEST, FETCH_SUCCESS, FETCH_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const accountInvite = (request) => {
  const url = 'user/v1/profile-invite/';
  return (dispatch) => {
    dispatch(fetchAccountPostRequest());
    authRequest(url, 'POST', request.data, request.token)
      .then((res) => {
        dispatch(fetchAccountPostSuccess(res.data));
      })
      .catch((error) => {
        dispatch(fetchAccountPostFailure(error));
      });
  };
};

export const fetchAccountPostRequest = () => {
  return {
    type: FETCH_REQUEST,
  };
};

export const fetchAccountPostSuccess = (result) => {
  return {
    type: FETCH_SUCCESS,
    payload: result,
  };
};

export const fetchAccountPostFailure = (error) => {
  return {
    type: FETCH_FAILURE,
    payload: error,
  };
};
