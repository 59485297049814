import axios from 'axios';
import { FETCH_OTHER_REQUEST, FETCH_OTHER_SUCCESS, FETCH_OTHER_FAILURE } from '../constants';
import { authRequest } from '../ApiResolver';

export const documentGetOther = (request) => {
  return (dispatch) => {
    dispatch(fetchDocumentsGetOtherRequest());
    const url =
      'second-opinion/v1/document/' + request.id + '/?category=OtherDocuments&limit=5&offset=' + request.offset;
    authRequest(url, 'GET', null, request.token)
      .then((res) => {
        let response = res.data.data;
        dispatch(fetchDocumentsGetOtherSuccess(response));
      })
      .catch((error) => {
        dispatch(fetchDocumentsGetOtherFailure(error));
      });
  };
};

export const fetchDocumentsGetOtherRequest = () => {
  return {
    type: FETCH_OTHER_REQUEST,
  };
};

export const fetchDocumentsGetOtherSuccess = (result) => {
  return {
    type: FETCH_OTHER_SUCCESS,
    payload: result,
  };
};

export const fetchDocumentsGetOtherFailure = (error) => {
  return {
    type: FETCH_OTHER_FAILURE,
    payload: error,
  };
};
