import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router';
import { withRouter } from 'react-router-dom';
import Loader from 'react-loader-spinner';
import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, ClickAwayListener } from '@material-ui/core';
import ImageIcon from '@material-ui/icons/Image';

import { notificationPost } from '../data';
import { getUser, getToken } from '../selectors';

import '../css/notifications.css';

import * as storage from '../utilities/localStorageUtility';
import * as utilityFunctions from '../utilities/helperFunctions';

import * as style from '../styleConstants';
import { StylesContext } from '@material-ui/styles';

const useStyles = style.notificationStyle;

function NotificationsList(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.notificationResult);
  const { loading, result, error } = data;
  const token = useSelector((state) => getToken(state));
  const user = useSelector((state) => getUser(state));
  const [typeId, setTypeId] = useState(0);
  const [type, setType] = useState('');
  const [redirect, setRedirect] = useState(false);
  const [redirectState, setRedirectState] = useState(false);
  const [viewed, setViewed] = useState(false);
  const [loader, setLoader] = useState(props.show);
  const [responseText, setResponseText] = useState('');

  const displayText =
    user.type === 'Patient' ? ' responded to your consultation request' : ' has requested you for a consultation';

  useEffect(() => {
    if (!loading) {
      setLoader(true);
    }
    if (redirectState && !loading && result.constructor === Object) {
      setRedirect(true);
      setRedirectState(false);
      setLoader(true);
    } else {
      setRedirect(false);
    }
  }, [loading, redirect]);

  function postData(id, typeID, type, viewed, text) {
    setResponseText(text);
    setViewed((v) => !v);
    setRedirectState(true);
    setTypeId(typeID);
    setType(type);
    if (!viewed) {
      const data = { viewed: true };
      const obj = { token, id, data };
      dispatch(notificationPost(obj));
    } else {
      setRedirect(true);
    }
  }

  const handleClickAway = () => {
    props.setOpen(false);
  };

  function loadMore() {
    setLoader(false);
    props.reload(props.limit + 10);
  }

  if (!redirect) {
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div id='drop-down'>
          <div className='notifications-container'>
            <List className={classes.root}>
              {props.count > 0 ? (
                props.notifications.map((notification, index) => (
                  <ListItem
                    className='list-item'
                    style={!notification.viewed ? { backgroundColor: '#FFEEF0', fontWeight: 'bold' } : null}
                    onClick={() =>
                      postData(
                        notification.id,
                        notification.type_id,
                        notification.type,
                        notification.viewed,
                        notification.text
                      )
                    }>
                    <ListItemAvatar>
                      <Avatar style={{ width: '48px', height: '48px' }}>
                        {notification.picture === null ? (
                          <ImageIcon />
                        ) : (
                          <img
                            src={notification.picture}
                            className='notification-pic-radius'
                            alt='profile'
                            width='48'
                            height='48'
                          />
                        )}
                      </Avatar>
                    </ListItemAvatar>

                    <ListItemText
                      style={{
                        fontFamily: 'geomanistregular',
                        color: '#464E5F',
                      }}
                      secondary={
                        <>
                          <span className='notification-name' style={{ display: 'block', marginBottom: '-1px' }}>
                            {notification.sender_name}
                          </span>
                          <Typography
                            component='span'
                            variant='body2'
                            style={{
                              fontFamily: 'geomanistregular',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '24px',
                              color: '#464E5F',
                            }}>
                            {displayText}
                          </Typography>
                          <p className='notification-message'>
                            {utilityFunctions.dateFormatter(notification.created_at.split('T')[0], 'ddd MMMM DD, YYYY')}
                          </p>
                        </>
                      }
                    />
                  </ListItem>
                ))
              ) : (
                <ListItem>
                  <ListItemAvatar>
                    <Avatar>
                      <ImageIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary='No new notifications' secondary='Check in later' />
                </ListItem>
              )}
              {props.notifications.length > 0 && props.limit < props.count && loader ? (
                <div className='see-more-center' onClick={loadMore}>
                  <p>See more</p>
                </div>
              ) : null}
              {!loader ? <Loader type='Bars' color='#273238' height={40} width={40} /> : null}
            </List>
          </div>
        </div>
      </ClickAwayListener>
    );
  }
  if (type === 'Consultation') {
    if (user.type === 'Patient') {
      const opinionRequestPage = storage.getOpinionRequestBoolean() === 'true';
      if (opinionRequestPage) window.location.href = window.location.href;
      return (
        <Redirect
          to={{
            pathname: `/opinionrequests/${typeId}`,
            search: utilityFunctions.appendText(responseText, false),
            state: { id: typeId, uneditable: true },
          }}
        />
      );
    }
    const opinionRequestPage = storage.getOpinionRequestBoolean() === 'true';
    if (opinionRequestPage) window.location.href = window.location.href;
    return (
      <Redirect
        to={{
          pathname: `/opinionrequests/${typeId}`,
          search: utilityFunctions.appendText(responseText, true),
          state: { id: typeId },
        }}
      />
    );
  }
  return <div>Notification</div>;
}

export default withRouter(NotificationsList);
