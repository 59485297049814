import React from 'react';
import { Grid, Box } from '@material-ui/core';

import Logo from '../resources/logo.svg';
import facebookIcon from '../resources/facebook-icon.svg';
import twitterIcon from '../resources/twitter-icon.svg';
import linkedinIcon from '../resources/linkedin-icon.svg';
import locationIcon from '../resources/location-icon.svg';

import '../css/landingpage.css';
import { NavLink } from 'react-router-dom';

const LandingPageFooter = () => {
  const openLink = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box width='100%' sx={{ m: 0 }}>
      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        flexDirection='column'
        alignItems='center'
        sx={{ mt: 8 }}
        bgcolor='#E7EBF1'
        className='footer-container'>
        <Box width={{ xs: '100%', lg: '83%' }} height='100%' display='flex' alignItems='center' className='footer'>
          <img src={Logo}></img>
          <Box
            display='flex'
            alignItems='center'
            flexDirection='row'
            width='100%'
            ml={{ xs: '0px', md: '40px' }}
            className='copyright-container'>
            <Box display='flex' className='copyright' justifyContent={'start'}>
              <NavLink to='/termsandcondition' className='terms-link m-2'>
                Terms and Conditions
              </NavLink>
              <NavLink to='/privacypolicy' className='terms-link m-2'>
                Privacy Policy
              </NavLink>
            </Box>
          </Box>
          <Box display='flex' width='360px' className='contact-us'>
            <Box component='button' className='footer-button'>
              <NavLink to='/contactus' className='footer-link'>
                Contact Us
              </NavLink>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='space-between' width='176px'>
              <Box
                className='social-media-button shadow-interaction'
                onClick={() => openLink('https://www.facebook.com/ourexpertdoc1')}>
                <img src={facebookIcon}></img>
              </Box>
              <Box
                className='social-media-button shadow-interaction'
                onClick={() => openLink('https://twitter.com/ourexpertdoc')}>
                <img src={twitterIcon}></img>
              </Box>
              <Box className='social-media-button shadow-interaction'>
                <img src={linkedinIcon} onClick={() => openLink('https://www.linkedin.com/in/ourexpertdoc/')}></img>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        flexDirection='row'
        bgcolor='#5181d9'
        className='copyright-container'
        height={'24px'}></Box>

      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        flexDirection='row'
        alignItems='center'
        bgcolor='#5181d9'
        className='copyright-container'>
        <Box height='100%' display='flex' alignItems='center' className='copyright'>
          <Box display='flex' flexDirection='row'>
            <img src={locationIcon}></img>
            <span>5028 Neiman Cove Raleigh, NC 27612 USA</span>
          </Box>

          <span>© Copyright 2021. All Right Reserved.</span>
        </Box>
      </Box>
      <Box
        display='flex'
        justifyContent='center'
        width='100%'
        flexDirection='row'
        bgcolor='#5181d9'
        className='copyright-container'
        height={'24px'}></Box>
    </Box>
  );
};

export default LandingPageFooter;
