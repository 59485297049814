import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { Grid, Box, makeStyles } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ClearIcon from '@material-ui/icons/Clear';

import '../css/dropdownnav.css';

import dummyProfile from '../resources/dummy-profile.svg';
import { getFullName, signUserOut } from '../utilities/helperFunctions';

function DropDownHeader(props) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.up('xs'));
  const useStyles = makeStyles({
    grid: {
      justifyContent: smallScreen ? 'flex-start' : 'flex-start',
    },
  });
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const picture = user.picture === null ? dummyProfile : user.picture;

  return (
    <div className='dropdownheader'>
      <Box display='flex'>
        <Grid container xs={12} className={classes.grid}>
          <div class='cross-dropdownmenu'>
            <div class='person-description'>
              <div>
                <Grid
                  container
                  xs={12}
                  className='basic-spacing'
                  alignItems='flex-start'
                  justify='flex-start'
                  direction='row'>
                  <div className='account-dropdown-v1'>
                    <img src={picture} className='header-image-dropdown' alt='profile' width='48' height='48' />
                  </div>
                </Grid>
              </div>

              <div>
                <Grid
                  container
                  xs={12}
                  className='basic-spacing'
                  alignItems='flex-start'
                  justify='flex-start'
                  direction='row'>
                  <p className='text-decorator-white'>{getFullName(user)}</p>
                </Grid>
                <Grid
                  container
                  xs={12}
                  className='basic-spacing'
                  alignItems='flex-start'
                  justify='flex-start'
                  direction='row'>
                  <p className='text-decorator-grey'>{user.type}</p>
                </Grid>
              </div>
            </div>
            <div className='cross' onClick={() => props.closeDropDown()}>
              <ClearIcon></ClearIcon>
            </div>
          </div>
          {user.type === 'Patient' ? (
            <Grid
              container
              xs={12}
              className='basic-extra-spacing'
              alignItems='flex-start'
              justify='flex-start'
              direction='row'>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/' className='droplink'>
                  Home
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/profile' className='droplink'>
                  Profile
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/viewmedicalprofile' className='droplink'>
                  Medical Profile
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/previousrecords' className='droplink'>
                  Previous Medical Records
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/yourrequests' className='droplink'>
                  Request Consultation
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <div className='droplink'>
                  <a href='' onClick={signUserOut}>
                    Sign out
                  </a>
                </div>
              </Grid>
            </Grid>
          ) : user.type === 'Physician' ? (
            <Grid
              container
              xs={12}
              className='basic-extra-spacing'
              alignItems='flex-start'
              justify='flex-start'
              direction='row'>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/' className='droplink'>
                  Home
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/profile' className='droplink'>
                  Profile
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/unsubmittedopinionrequest' className='droplink'>
                  Opinion Requests
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/submittedopinionrequest' className='droplink'>
                  Submitted Opinions
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <div className='droplink'>
                  <a href='' onClick={signUserOut}>
                    Sign out
                  </a>
                </div>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              xs={12}
              className='basic-extra-spacing'
              alignItems='flex-start'
              justify='flex-start'
              direction='row'>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/' className='droplink'>
                  Home
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/profile' className='droplink'>
                  Profile
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/profilerequests' className='droplink'>
                  Account Requests
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/add' className='droplink'>
                  Add Medical Data
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/selectphysician' className='droplink'>
                  Approved Physician
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/createaccount' className='droplink'>
                  Create Accounts
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <NavLink to='/invite' className='droplink'>
                  Invite Physicians
                </NavLink>
              </Grid>
              <Grid
                container
                xs={12}
                className='basic-extra-spacing'
                alignItems='flex-start'
                justify='flex-start'
                direction='row'>
                <div className='droplink'>
                  <a href='' onClick={signUserOut}>
                    Sign out
                  </a>
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Box>
    </div>
  );
}

export default DropDownHeader;
