import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import allReducers from './data/reducers';
import { Provider } from 'react-redux';
import theme from './components/Theme';
import { ThemeProvider } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { signUserOut } from './utilities/helperFunctions';
Amplify.configure(awsExports);

const store = createStore(
  allReducers,
  applyMiddleware(thunk)
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

axios.interceptors.request.use((request) => {
  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    /** if the user does not have valid authorization token (ie it has expired) */
    if (error.response.status === 401) {
      signUserOut();
      window.location.href = '/signin';
    }
    return Promise.reject(error);
  }
);

const stripeKey = process.env.REACT_APP_STRIPE_KEY;

const stripePromise = loadStripe(stripeKey);

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Elements stripe={stripePromise}>
      <Provider store={store}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </Provider>
    </Elements>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
